import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Hi! 👋`}</h2>
    <p>{`I'm Gerard Altamirano, self-taught software developer. CS enthusiast & teaching lover ❤️.`}</p>
    <p><strong parentName="p">{`I taught with:`}</strong></p>
    <p>{`📌 "Code en mi cole" - Ministery of Education. Lima, Peru 📍`}</p>
    <p>{`📌 "Refuerzo en mi cole" - Municipality of Lima. Lima, Peru 📍`}</p>
    <p>{`📌 Hmm... This seems a bit empty, Would you like to help me? `}<a parentName="p" {...{
        "href": "mailto:hello@itsgerard.com"
      }}>{`hello@itsgerard.com`}</a>{` 👀  `}</p>
    <p>{`💻 Currently Software Engineer at `}<a parentName="p" {...{
        "href": "http://able.co/"
      }}>{`Able`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      